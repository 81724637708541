import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import Logo from "../../assets/Logo.jpg"
import "./SignUp.css"


export default function SignUp() {
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(false)


  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }


  const successNotifier = (data) => {

    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const errorNotifier = (data) => {

    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const handleRegister = async (e) => {
    console.log(state)
    e.preventDefault()
    try {
      setIsLoading(true)
      const userData = await request("/user/registerNewUser", "POST", {
        "Content-Type": "application/json"
      }, { ...state })

      if (userData.status === 200) {

        successNotifier(userData.data)
      } else {
        errorNotifier(userData.data)
      }

      setIsLoading(false)


    } catch (error) {
      console.log(error)
    }
  }

  const ShowPassword = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return (
    <div className='w-[100%]'>
           <ToastContainer />
       <div className="h-[100vh] w-[100%] flex justify-center items-center bg-slate-50">
      <div className="flex min-h-[65vh] w-[60%] m-2 justify-center items-center px-6 py-12 lg:px-8 border-2 border-gray-500 rounded-lg Form">

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Link to="/">
            <img
              alt="Your Company"
              src={Logo}
              className="mx-auto h-20 w-52"
            />
          </Link>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create New Account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleRegister} className="space-y-6">
            <div className="flex gap-2">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Enter Name
                </label>
                <div className="mt-2">
                  <input
                    name="userName"
                    type="text"
                    required
                    className="block w-full rounded-md border-2 p-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState} />

                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="userEmail"
                    type="email"
                    required
                    className="block w-full rounded-md border-2 p-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState}
                  />
                </div>
              </div>

            </div>

            <div className="flex gap-2">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Phone Number
                </label>
                <div className="mt-2">
                  <input
                    name="phonNumber"
                    type="text"
                    required
                    className="block w-full rounded-md border-2 p-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Gender
                </label>
                <div className="mt-2">

                  <select
                    type="text"
                    name="gender"
                    required
                    className="block w-full rounded-md border-2 p-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState}
                  >
                    <option hidden>Chose...</option>
                    <option value="Male">Male</option>
                    <option value='Female'>Female</option>
                    <option value="Others">Others</option>

                  </select>
                </div>
              </div>

            </div>


            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="myInput"
                  name="password"
                  type="password"
                  required
                  className="block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleState}
                />
                <div className='pt-2'>
                  Show Password
                  <input
                    type="checkbox"
                    className="form-check-input ml-1 "
                    id="gridCheck"
                    onClick={ShowPassword}
                  />
                </div>
              </div>
            </div>




            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={isLoading === true}
              >
                Sign Up
                {isLoading &&
                  <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                    <span className="sr-only"></span>
                  </div>}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already Have Account?{' '}
            <Link to="/Login" className="font-semibold leading-6 text-indigo-600 no-underline hover:text-indigo-500">Login</Link>
          </p>
        </div>
      </div>
    </div>
    </div>



  )
}