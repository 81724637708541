/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util/fetchAPI'
import Preloader from '../Preloader/Preloader';
import IMG1 from "../../assets/IMG (1).jpeg";
import "./NewArival.css";

export default function Products() {

  const [productData, setProductData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()


  //Fetch Products Catagories
  const fetechProducts = async () => {
    try {
      setIsLoading(true)
      const productData = await request("/product/getAllProducts", "GET")
      setProductData(productData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetechProducts()
  }, [])

  return (
    <div className='mt-20'>
      <h3 className='flex justify-center items-center font-bold text-5xl text-gray-500 Heading'>
        NEW ARIVALS
      </h3>
      <div className="NewArivalProducts">
        {isLoading ?
          <Preloader />
          :
          productData.map((data) => (
            <div key={data._id} className=" mx-auto cursor-pointer max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              <img
                className="rounded-t-lg w-[100%] h-[50%] p-1 rounded-lg"
                src={data.productImg ? `https://api.ishaqkhantruckart.com/images/${data.productImg}` : "NULL"}
                alt=""
              />
              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {data.productName}
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {data.productDec}
                </p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  <span className='font-semibold text-gray-800'>SIZE</span> : {data.productSize}
                </p>
                <p className="mb-3 font-semibold text-black dark:text-gray-400">
                  <span className='text-xl text-slate-700 line-through'>{data.promoPrice}.00</span> <span className='text-2xl ml-2'>{data.productPrice}.00</span>
                </p>
                <div className='flex gap-2'>
                  <div
                    className="inline-flex no-underline items-center px-3 py-2 text-sm font-medium text-center text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => { navigate(`/ProductsPreview/${data._id}`) }}
                  >
                    Read More
                    <svg
                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </div>

                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>

  );
}
