/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { request } from '../../util/fetchAPI';
import "./OrderConfirmation.css"
import { Link, useParams } from 'react-router-dom'

export default function OrderConfirmation() {
  const [orderData ,setOrderData] = useState({})
  const {id} = useParams()

  const grandTotal = orderData?.products?.reduce((total, data) => {
    return total + data.productPrice * data.qtyOrder;
  }, 0);

  const fetchOrderInfo = async(id)=>{
    try {

      const data = await request(`/order/trackOrderBy/${id}`, "GET")
      setOrderData(data)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchOrderInfo(id)
  },[id])

  return (
  <section className="h-auto" style={{ backgroundColor: '#eee' }}>
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-8 col-xl-6">
          <div
            className="card border-top border-bottom border-3"
            style={{ borderColor: '#f37a27 !important' }}
          >
            <div className="card-body p-5">
              <p className="lead fw-bold mb-5 text-indigo-800">
                Purchase Receipt
              </p>

              <div className="row">
                <div className="col mb-3">
                  <p className="small text-muted mb-1">Date</p>
                  <p>{orderData.OrderData?.createdAt.slice(0,10)}</p>
                </div>
                <div className="col mb-3">
                  <p className="small text-muted mb-1">Order No.</p>
                  <p>{orderData.OrderData?.orderId}</p>
                </div>
              </div>

              {orderData?.products?.map((data)=>(
                <div
                className="mx-n5 px-5 py-4"
                style={{ backgroundColor: '#f2f2f2' }}
              >
                <div className="row">
                  <div className="col-md-8 col-lg-9">
                    <p>{data.productId?.productName} x {data.qtyOrder}</p>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <p>Size: {data.productId?.productSize}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-lg-9">
                    <p className="mb-0">Material : {data.productId?.productMaterial}</p>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <p className="mb-0">{data.productPrice}.00</p>
                  </div>
                </div>
              </div>
              ))}
              <div className="row my-4">
                <div className="col-md-4 offset-md-8 col-lg-3 offset-lg-9">
                  <p className="lead fw-bold mb-0 text-indigo-800">
                    {grandTotal}.00
                  </p>
                </div>
              </div>

              <p className="lead fw-bold mb-4 pb-2 text-indigo-800">
                Tracking Order
              </p>

              <div className="row">
                <div className="col-lg-12">
                  <div className="horizontal-timeline">
                    <ul className="list-inline items d-flex justify-content-between">
                      <li className="list-inline-item items-list">
                        <p
                          className={orderData.OrderData?.isProcessed === true ? "py-1 px-2 rounded bg-lime-700 text-white" :" text-black"}>
                          Ordered
                        </p>
                      </li>
                      <li className="list-inline-item items-list">
                      <p
                          className={orderData.OrderData?.isConfirmed === true ? "py-1 px-2 rounded bg-lime-700 text-white" :" text-black"}>
                          Confirmed
                        </p>
                      </li>
                      <li className="list-inline-item items-list">
                      <p
                          className={orderData.OrderData?.isShipped === true ? "py-1 px-2 rounded bg-lime-700 text-white" :" text-black"}>
                          On the way
                        </p>
                      </li>
                      <li className="list-inline-item items-list text-end" style={{ marginRight: 8 }}>
                      <p
                          className={orderData.OrderData?.isDelivered === true ? "py-1 px-2 rounded bg-lime-700 text-white" :" text-black"}>
                          Delivered</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p className="mt-4 pt-2 mb-0">
                Want any help?{' '}
                <Link to='/Contact-Us' style={{ color: '#f37a27' }}>
                  Please contact us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> )
}
