/* eslint-disable */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from "../../redux/authSlice"
import { IoSearchOutline, IoLogInOutline, IoCartOutline,IoClose }
  from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import IMG2 from "../../assets/IMG (3).jpg";
import Logo from "../../assets/Logo.jpg"
import "./NavBar.css"

export default function NavBar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.items);
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const navigator = (key) => {
    navigate(key);
    setIsMenuOpen(false);
    document.body.classList.remove('no-scroll'); // Re-enable scroll
  };

  const toggleMenu = () => {
     setIsMenuOpen((prev) => !prev);
     if (!isMenuOpen) {
       document.body.classList.add('no-scroll'); // Disable scroll
     } else {
       document.body.classList.remove('no-scroll'); // Re-enable scroll
     }
  };

  return (
    <div className='flex flex-col flex-1 gap-1'>
      <div className='flex justify-end m-2 pr-3 gap-3 cursor-pointer UpperNav'>
        <div>
          <span>
            <IoSearchOutline className='icons' onClick={() => setIsSearchModalOpen((prev) => !prev)} />
            {isSearchModalOpen && (
              <div className='searchInput'>
                <input type="text" className='text-white bg-black pl-1' placeholder='Search...' />
              </div>
            )}
            {!isSearchModalOpen && "Search"}
          </span>
        </div>
        <div>
          <Link to="/CheckOut" className='no-underline'>
            <span><IoCartOutline className='icons' />Cart - {cartItems.length}</span>
          </Link>
        </div>
        <div>
          {user ? (
            <>
              <img src={IMG2} alt="User" className='w-9 h-9 rounded-full cursor-pointer border-white border-2' onClick={() => setIsModalOpen((prev) => !prev)} />
              {isModalOpen && (
                <div className="absolute flex flex-col gap-2 bg-slate-100 border-2 p-2 shadow-lg rounded-lg min-h-32 min-w-44 right-0">
                  <span className='bg-white text-black'>My Profile</span>
                  <span className='bg-white text-black'>My Orders</span>
                  <span className='bg-white text-black' onClick={handleLogout}>Sign Out</span>
                </div>
              )}
            </>
          ) : (
            <Link to="/Login" className='no-underline'>
              <span><IoLogInOutline className='icons' />Login</span>
            </Link>
          )}
        </div>
      </div>

      <div className='w-[100%] flex justify-center items-center p-2'>

        {/* Mobile Navbar */}
        <div className='MobNavContainer'>

          <IoMdMenu className='text-2xl font-bold p-2 w-10 h-10 bg-black text-white rounded-full' onClick={toggleMenu}/>


          <div className={`sideNav h-full ${isMenuOpen ? 'open' : ''}`}>
            <ul>
              <li onClick={() => navigator("/")}>HOME</li>
              <li onClick={() => navigator("/NewArrival")}>NEW ARRIVALS</li>
              <li onClick={() => navigator("/Products")}>SHOP PRODUCTS</li>
             {/*  <li onClick={() => navigator("/")}>SERVICES</li> */}
              <li onClick={() => navigator("/OurGallery")}>OUR GALLERY</li>
              <li onClick={() => navigator("/TrackMyOrder")}>TRACK MY ORDER</li>
              <li onClick={() => navigator("/Contact-Us")}>CONTACT US</li>
            </ul>

            <div>
            <IoClose className='absolute top-0 right-0 text-2xl font-bold p-2 w-10 h-10  text-red-600 rounded-full' onClick={toggleMenu}/>
            </div>
          </div>
        </div>
        <div className='mobileLogo'>
          <Link to="/">
            <img src={Logo} className='h-12 w-52' alt="Logo" />
          </Link>
        </div>
      </div>


      {/* Desktop Navbar */}
      <div className='w-[100%] flex justify-center items-center'>
        <ul className='flex cursor-pointer p-2 Menue'>

          <div className='left-0'>
            <Link to="/">
              <img src={Logo} className='h-12 w-52' alt="Logo" />
            </Link>
          </div>

          <div className='flex justify-center items-center w-[100%] gap-10 DesktopNavBar'>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/NewArrival">NEW ARRIVALS</Link></li>
            <li><Link to="/Products">SHOP PRODUCTS</Link></li>
            {/* <li><Link to="/">SERVICES</Link></li> */}
            <li><Link to="/OurGallery">OUR GALLERY</Link></li>
            <li><Link to="/TrackMyOrder">TRACK MY ORDER</Link></li>
            <li><Link to="/Contact-Us">CONTACT US</Link></li>
          </div>
        </ul>
      </div>
    </div>
  );
}
