import React from 'react'
import HEROPIC from "../../assets/Hero.png"
import "./Home.css"

export default function Home() {
  return (
    <div>
        <div className='container flex justify-center items-center h-[90vh] HomeContainer'>
        <img src={HEROPIC} alt="" className='w-[95%] h-[100%] rounded-lg'/>
        </div>
    </div>
  )
}
