import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { clearCart } from '../../redux/cartSlice';
import { request } from '../../util/fetchAPI';
import { useSelector, useDispatch } from 'react-redux';
import { cancelCheckout } from '../../redux/checkOutSlice';
import { RxCross2 } from "react-icons/rx";
/* import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; */
import "./Billing.css"


export default function Billing() {
  const [state, setState] = useState()
  const [paymentMethodData, setPaymentData] = useState([])
  const [selectedPM, setselectedPM] = useState([]) //PM (Payment Method)
  const cartItems = useSelector(state => state.cart.items);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // Calculate the grand total dynamically
  const grandTotal = cartItems.reduce((total, item) => total + (item.productPrice * item.quantity), 0);

  const successNotifier = () => {
    toast.success("Order Has Been Generated", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };


  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handlePaymentState = (e) => {
    setselectedPM((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const cancleOrder = () => {
    dispatch(cancelCheckout())
  }

  //Fetch Products Catagories
  const fetechPaymentMethods = async () => {
    try {
      const paymentMethodData = await request("/payment/getAllPeymentMethods", "GET")
      setPaymentData(paymentMethodData)

    } catch (error) {
      console.log(error)
    }
  }

  const handleOrderRegister = async (e) => {
    e.preventDefault()
    try {

        const Data = await request("/order/registerOrder", "POST", {
          "Content-Type" : "application/json"
        }, {clientId:state, paymentMethod:selectedPM, cartItems})

        if(Data.status === 200){
          successNotifier()
          dispatch(clearCart());
          dispatch(cancelCheckout())
          navigate(`/OrderConfirmation/${Data.data}`)
        }else{
          errorNotifier(Data.data)
        }


    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetechPaymentMethods()
  }, [])

  return (
    <div>
 <ToastContainer />
      <h3 className='flex w-[100%] justify-center items-center font-bold text-5xl text-gray-500 Heading'>
        Billing Info
      </h3>

      <div className='flex w-[screen] m-3'>
        <button className='flex bg-red-500 p-2 text-white rounded-md' onClick={() => cancleOrder()}>Cancle Order<RxCross2 className='font-bold text-2xl ml-2' /></button>
      </div>

      <div className='BillingForm' >

        <div className='RightSizeSummary p-3'>
          <h4>Order Summary</h4>
          <h3 className='mx-auto'>Total Amount</h3>
          <h3 className='mx-auto'>PKR = {grandTotal}.00</h3>
        </div>



        <div className='LeftSizeForm'>

          <form onSubmit={handleOrderRegister}>
            <fieldset className="p-1">
              <legend className="font-bold text-blue-700">Personal Information</legend>

              <div className="row mb-2 mobileForm">
                <div className="col">
                  <label htmlFor="name" className="block mb-2">Enter Name</label>
                  <input type="text" name='clientName' id="name" className="form-control w-full p-2 border border-gray-300" placeholder="Enter Full Name" onChange={handleState} required/>
                </div>

                <div className="col">
                  <label htmlFor="email" className="block mb-2">Enter Email</label>
                  <input type="email" name='clientEmail' id="email" className="form-control w-full p-2 border border-gray-300" placeholder="example@gmail.com" onChange={handleState} required/>
                </div>
              </div>

              <div className="row mb-2 mobileForm">
                <div className="col">
                  <label htmlFor="contact1" className="block mb-2">Contact No. # 1 (Primary)</label>
                  <input type="text" name='clientContact1' id="contact1" className="form-control w-full p-2 border border-gray-300" placeholder="03xx xxxxxxx" onChange={handleState} required/>
                </div>

                <div className="col">
                  <label htmlFor="contact2" className="block mb-2">Contact No. # 2</label>
                  <input type="text" name='clientContact2' id="contact2" className="form-control w-full p-2 border border-gray-300" placeholder="03xx xxxxxxx" onChange={handleState} required/>
                </div>
              </div>
            </fieldset>

            <fieldset className="border-2 border-black p-1">
              <legend className="font-bold text-blue-700">Address Information</legend>

              <div className="row mb-2 mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block'>House #</label>
                  <input type="text" name='houseNo' className="form-control" placeholder="Enter House No. #" onChange={handleState} required/>
                </div>

                <div className="col">
                  <label htmlFor="" className=' block'>Street #</label>
                  <input type="text" name='streetNo' className="form-control" placeholder="Enter Street No. #" onChange={handleState} required/>
                </div>
              </div>

              <div className="row mb-2 mobileForm">
              <div className="col">
                  <label htmlFor="" className=' block'>Landmark</label>
                  <input type="text" name='landMark' className="form-control" placeholder="Enter Popular Landmark" onChange={handleState} required/>
                </div>

                <div className="col">
                  <label htmlFor="" className=' block'>City</label>
                  <input type="text" name='City' className="form-control" placeholder="Enter City Like Rawalpindi" onChange={handleState} required/>
                </div>
              </div>
              <div className="row mb-2 mobileForm">
              <div className="col">
                  <label htmlFor="" className=' block'>State</label>
                  <select  type="text" name='State' className="form-control" placeholder="Enter State Like Pubjab" onChange={handleState} required>
                    <option hidden>Chose ....</option>
                  <option value="punjab">Punjab</option>
                  <option value="sindh">Sindh</option>
                  <option value="khyber_pakhtunkhwa">Khyber Pakhtunkhwa</option>
                  <option value="balochistan">Balochistan</option>
                  <option value="ajk">Azad Jammu and Kashmir</option>
                  <option value="gb">Gilgit-Baltistan</option>
                  </select>
                </div>

                <div className="col">
                  <label htmlFor="" className=' block'>Postal Code</label>
                  <input type="text" name='postalCode' className="form-control" placeholder="Enter PostalCode Like 42000" onChange={handleState} required/>
                </div>
              </div>

              <div className="row mb-2 mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block'>Shipping Address</label>
                  <textarea type="text" name='clientAddress' className="form-control" placeholder="Enter Full Address" onChange={handleState} required/>
                </div>
              </div>

            </fieldset>


            <fieldset className="border-2 border-black p-1">
              <legend className="font-bold text-blue-700">Mode Of Payment</legend>

              <div className="row mobileForm">
                <div className="col">
                  <label htmlFor="" className=' block'>Select Payment Option</label>

                  <select name="MOP" className="form-control" id="" onChange={(e) =>{
                    setselectedPM(JSON.parse(e.target.value))}}>
                    <option hidden>Chose ....</option>
                    {paymentMethodData.map((data) => (
                      <option key={data._id} value={JSON.stringify(data)}>{data.bankName}</option>
                    ))}

                  </select>
                </div>
              </div>
            </fieldset>

            {paymentMethodData.filter((data) => data._id === selectedPM._id && selectedPM.bankName !== "Cash On Delivery").map((data) => (
              <div key={data._id} className='w-[100%] min-h-56 border-2 border-gray-500 rounded-lg'>
                <fieldset className="border-2 border-black p-1">
                  <legend className="font-bold text-blue-700">Account Details</legend>
                  <div className='grid p-2 gap-2'>
                    <span className='font-bold'>Bank Name :</span>
                    <span className='text-gray-700'>{data.bankName}</span>
                    <span className='font-bold'>Account Title :</span>
                    <span className='text-gray-700'>{data.accountTitle}</span>
                    <span className='font-bold'>Account No. :</span>
                    <span className='text-gray-700'>{data.accountNumber}</span>
                    <span className='font-bold'>Note:</span>
                    <span className='text-gray-700'>
                    {data.Note}
                    </span>

                  <div className='mt-2'>
                      <p className='text-red-600 font-semibold'>After the bank tranfer/ online payment. Kinldy fill below form to verify your transection.</p>
                      <p className='text-red-600 font-semibold'>بینک ٹرانسفر/آن لائن ادائیگی کے بعد اپنے لین دین کی توثیق کرنے کے لیے برائے مہربانی نیچے کا فارم پُر کریں</p>
                  </div>

                    <div className="row mt-2 mobileForm">
                      <div className="col">
                        <label htmlFor="bankName" className='block'>Bank Name</label>
                        <input type="text" className="form-control" name="payerBankName" onChange={handlePaymentState}/>
                      </div>

                      <div className="col">
                        <label htmlFor="accountHolderName" className='block'>Account Holder Name</label>
                        <input type="text" className="form-control" name="payerAccountTitle" onChange={handlePaymentState}/>
                      </div>

                    </div>
                    <div className="row mt-2 mobileForm">

                    <div className="col">
                        <label htmlFor="transactionId" className='block'>Transaction Id</label>
                        <input type="text" className="form-control" name="payerTransactionId" onChange={handlePaymentState}/>
                      </div>

                      <div className="col">
                        <label htmlFor="accountHolderName" className='block'>Amount </label>
                        <input type="text" className="form-control" name="paidAmount" onChange={handlePaymentState}/>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            ))}

            <div>
              <button className='bg-indigo-600 text-white p-2 rounded-lg mt-3 mb-2 w-52'>Submit</button>
            </div>
          </form>

        </div>
      </div>


    </div>


  )
}
