/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux';
import Cart from '../Cart/Cart';
import Billing from '../Billing/Billing';
import "./CheckOut.css"

export default function CheckOut() {
  const isCheckingOut = useSelector(state => state.checkout.isCheckingOut); // Access checkout state

  return (
    <div className=' bg-slate-100'>


      {isCheckingOut ?
        <div>
          <Billing />
        </div>
        :
        <div>

          <Cart />
        </div>}
    </div>
  )
}
