/* eslint-disable */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { addItemToCart } from '../../redux/cartSlice';
import { request } from '../../util/fetchAPI';
import Preloader from '../Preloader/Preloader';
import { FaStar } from 'react-icons/fa';
import "./ProductPreview.css"
import "bootstrap/dist/css/bootstrap.min.css"


//All Images
import IMG4 from "../../assets/IMG (5).jpg";
import { useParams } from 'react-router-dom';

export default function ProductPreview() {
  const reviews = { href: '#', average: 4, totalCount: 10 }
  const [isLoading, setIsLoading] = useState(false)
  const [photo, setPhoto] = useState();
  const [productQty, setProductQty] = useState(1)
  const [state, setState] = useState()
  const [product, setProduct] = useState([])
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams()

  const successNotifier = () => {

    toast.success("Product Added To Cart", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  }

  const successReviewNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }
  const errorReviewNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });

  }

  const addToCartHandler = (product) => {
    setIsLoading(true)
    dispatch(addItemToCart({ product }));
    setIsLoading(false)
    successNotifier()
  };


  const stateHandler = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const fetchProducts = async (id) => {
    try {
      setIsLoading(true)
      const productData = await request(`/product/productBy/${id}`, "GET")
      setProduct(productData)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);

    } catch (error) {
      console.log(error)
    }
  }

  const reviewHandler = async (e) => {
    e.preventDefault()
    try {

      let filename = null
      const formData = new FormData();
      if (photo) {
        filename = crypto.randomUUID() + photo.name;
        formData.append("filename", filename);
        formData.append("image", photo);
        await fetch(`https://api.ishaqkhantruckart.com/upload/image`, {
          headers: {},
          method: "POST",
          body: formData
        });
      }

      const productData = await request(`/productReview/giveReview`, "POST",
        {
          "Content-Type": "application/json"
        },
        { ...state, productId: id,productRating:rating,imgAttach: filename }
      )
      if (productData.status === 200) {
        successReviewNotifier(productData.data)
      } else {
        errorReviewNotifier(productData.data)
      }


    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {

    fetchProducts(id)
    window.scroll(0,0)

  }, [id])





  return (
    <div className="bg-white">
      <ToastContainer />
      {
      isLoading ?
      <Preloader/>
      :
      <>
        <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">

            <li key={product._id}>
              <div className="flex items-center">
                <span className="mr-2 text-sm font-medium text-gray-900">
                  Product Preview
                </span>
                <svg
                  width={16}
                  height={20}
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="h-5 w-4 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                </svg>
              </div>
            </li>

            <li className="text-sm">
              <span aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product?.productData?.productName}
              </span>
            </li>
          </ol>
        </nav>

        {/* Image gallery */}

        <div className="aspect-h-5 aspect-w-4 lg:aspect-h-1 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
          <img
            src={product?.productData?.productImg ? `https://api.ishaqkhantruckart.com/images/${product?.productData?.productImg}` : "NULL"}
            alt=''
            className="productImg"
          />

        </div>

        {/* Product info */}
        <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">   {product?.productData?.productName} <span className='text-gray-600 ml-5 text-xl line-through'>
            {product?.productData?.promoPrice}.00</span>   <span>PKR - {product.productData?.productPrice}.00</span> </h1>
            <p className="text-l font-bold tracking-tight text-gray-500 sm:text-l">Manufactured By :
              {product?.productData?.manufBy}</p>
          </div>
          <div>


            <div className="space-y-6">
              <h5 className="">Product Description</h5>
              <p className="text-base text-gray-900">{product?.productData?.productDec}</p>
            </div>
          </div>

          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h5 className="">Product Detail</h5>
            <p className="text-l font-bold tracking-tight text-gray-500 sm:text-l text-justify">
            {product?.productData?.productDetail}
             </p>
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">

            {/* Reviews */}
            <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">

                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <FaStar
                      key={rating}
                      className={
                        (reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                          'h-5 w-5 flex-shrink-0')
                      }
                      aria-hidden="true"
                    />
                  ))}
                </div>

                <p className="sr-only">{reviews.average}  out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount}  reviews
                </a>
              </div>
            </div>

            <form className="mt-3">

              {/* Sizes */}

              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Category</h3>


                </div>

                <div className="grid">
                  {product.productData?.productCat?.proCatDec
                  }
                </div>

              </div>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Product Size</h3>
                </div>

                <div className="grid grid-cols-6  sm:grid-cols-8 lg:grid-cols-4">
                  {product.productData?.productSize}
                </div>
              </div>


              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Material Used</h3>
                </div>

                <div className=" sm:grid-cols-8 lg:grid-cols-4">
                  {product.productData?.productMaterial}
                </div>

              </div>

              <div className="mt-4">
                <label className='block mb-1'>Quantity</label>
                <input type="Number" id="name" className="form-control w-full p-2 border border-gray-300" placeholder="Enter Quantity" min={1} value={productQty} onChange={(e) => setProductQty(e.target.value)} />
              </div>

              <div className="mt-5 text-xl sm:grid-cols-8 lg:grid-cols-4">
                TOTAL PKR - {productQty * product.productData?.productPrice}.00
              </div>

              <button
                type="button"  // Prevent form submission
                className="mt-10 flex w-full items-center justify-center rounded-md border
             border-transparent bg-lime-600 px-8 py-3 text-base font-medium text-white
             hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => addToCartHandler({ product:product.productData, productQty })}
              >
                ADD TO CART

                {isLoading &&
                  <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                    <span className="sr-only"></span>
                  </div>}

              </button>

            </form>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            {/* Description and details */}
          </div>
        </div>
      </div>
      <div className='p-5 m-5 bg-slate-300 rounded-xl'>
        <h3 className='text-xl font-semibold'>Please tell us about ourselves, or if you want anything new, let us know.</h3>

        <form className="lg:col-span-2" onSubmit={reviewHandler}>
          <div>
            {[...Array(5)].map((star, i) => {
              const ratingValue = i + 1;

              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="rating"
                    style={{ display: "none" }}
                    value={ratingValue}
                    onClick={() => setRating(ratingValue)}
                  />
                  <FaStar
                    className="star"
                    color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                    size={40}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                  />
                </label>
              );
            })}
            <p>The rating is {rating}.</p>
          </div>
          <div className="lg:col-span-2 mt-2">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
              <div className="md:col-span-2">

                <div className="form-floating mb-2 md-2">
                  <input type="text" className="form-control" id="floatingInput inputState" name="clientName"
                    onChange={stateHandler} placeholder='Enter Your Name' />
                  <label for="floatingInput">Customer Name</label>
                </div>
              </div>

              <div className="md:col-span-2">
                <div className="form-floating mb-2 md-2">
                  <input type="text" className="form-control" id="floatingInput inputState" name="clientEmail"
                    onChange={stateHandler} placeholder='Enter Your Email' />
                  <label for="floatingInput">Customer Email</label>
                </div>
              </div>

              <div className="md:col-span-4">
                <div className="form-floating mb-2 md-3">
                  <textarea type="text" className="form-control" id="floatingInput inputState" name="subject"
                    onChange={stateHandler} placeholder='Enter Your Subject' />
                  <label for="floatingInput">Subject</label>
                </div>
              </div>

              <div className="md:col-span-4">
                <div className="form-floating mb-2 md-3">
                  <textarea type="text" className="form-control" id="floatingInput inputState" name="message"
                    onChange={stateHandler} placeholder='Enter Your Message' />
                  <label for="floatingInput">Message</label>
                </div>
              </div>

              <div className="md:col-span-4">
                <div className="form-floating mb-2 md-3">
                  <input type="file" className="form-control" id="floatingInput inputState" name="message"
                    onChange={(e) => setPhoto(e.target.files[0])} />
                  <label for="floatingInput">Upload</label>
                </div>
              </div>

              <div className="md:col-span-5">
                <div className="inline-flex items-end">
                  <button className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='p-5 m-5 bg-slate-300 rounded-xl'>
        <h3 className='text-xl font-semibold'>Cutomer Reviews</h3>

        {product.reviewData?.map((data, index)=>(
          <div className='flex flex-col gap-3 p-3 bg-white rounded-md m-2'>
          <div className='flex'>
            <span className='flex'>{++index} - Product Ratting - {Array.from({ length: data.productRating }, (_, index) => (
        <FaStar key={index} className='text-lg ml-1 m-1' />
      ))}</span>
          </div>

          <div className='flex'>
            <span className='flex'>Post Date : </span>
            <span className='text-gray-500 ml-1'>{data.createdAt.slice(0,10)}</span>
          </div>

          <div className='flex flex-col gap-1 font-semibold'>
            <span>Cutomer Name </span>
            <span className='text-gray-500'>{data.clientName}</span>
          </div>

          <div className='flex flex-col gap-1 font-semibold'>
            <span>Cutomer Email </span>
            <span className='text-gray-500'>{data.clientEmail} </span>
          </div>

          <div className='flex flex-col gap-1 font-semibold'>
            <span>Subject</span>
            <span className='text-gray-500'>{data.subject} </span>
          </div>

          <div className='flex flex-col gap-1 font-semibold'>
            <span>Message</span>
            <span className='text-gray-500'>{data.message} </span>
          </div>
        </div>
        ))
          }

      </div>
      </>}
    </div>
  )
}

