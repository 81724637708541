/* eslint-disable */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { IoCartOutline } from "react-icons/io5";
import { startCheckout } from '../../redux/checkOutSlice';
import { removeItemFromCart, clearCart } from '../../redux/cartSlice'; // Import cart actions
import IMG2 from "../../assets/IMG (3).jpg";
import "./Cart.css";

export default function Cart() {

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
 const cartItems = useSelector(state => state.cart.items);

 console.log(cartItems)

  const successNotifier = () => {
    toast.success("Re-Directing To Billing", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const errorNotifier = () => {
    toast.error("Cart Is Empty Can't Process", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const CheckOutHandler = () => {
    setIsLoading(true)
    if (cartItems.length === 0) {
      errorNotifier();
    setTimeout(()=>{
      setIsLoading(false)
    },1000)

    } else {
      setIsLoading(true)
      successNotifier();

      setTimeout(() => {
        setIsLoading(false)
        dispatch(startCheckout());
      }, 1000);


    }
  };

  const handleClearCart = () => {
    dispatch(clearCart()); // Dispatch action to clear the cart
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItemFromCart(id)); // Dispatch action to remove an item
  };

  // Calculate the grand total dynamically
  const grandTotal = cartItems.reduce((total, item) => total + (item.productPrice * item.quantity), 0);

  return (
    <div className='min-h-[100vh] overflow-auto w-full p-2'>
      <ToastContainer />
      <h3 className='flex w-[100%] justify-center items-center font-bold text-5xl text-gray-500 Heading'>
        CART
      </h3>

      <h3 className='flex justify-start items-center p-3 font-bold text-lg text-black bg-white w-40 rounded-lg mb-4'>
        Product Profile
      </h3>

      <div className='flex w-[screen] m-3'>
        <button className='flex bg-red-500 p-2 text-white rounded-md' onClick={handleClearCart}>
          Clear Cart <IoCartOutline className='font-bold text-2xl ml-2' />
        </button>
      </div>

      <div className='flex justify-center ProductContainer'>
        <table className='text-center w-[95%] bg-[#069d30] text-white font-semibold rounded-lg text-lg ProductsTable'>
          <thead>
            <tr className='border-b-2 border-white'>
              <th className='w-10'>#</th>
              <th className='w-20'>Profile</th>
              <th>Name</th>
              <th>Cat</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((data, index) => (
              <tr key={data.id}> {/* Ensure each row has a unique key */}
                <td>{index + 1}</td>
                <td>
                  <img src={IMG2} alt="" className='w-16 h-16 rounded-full p-1 m-1 border-white border-2' />
                </td>
                <td className='w-96 text-wrap overflow-hidden'>{data.productName}</td>
                <td>{data.productCat}</td>
                <td>{data.productUnit}</td>
                <td>{data.quantity}</td>
                <td>{data.productPrice}.00</td>
                <td>{data.productPrice * data.quantity}.00</td>
                <td>
                  <span className='bg-red-600 text-white p-2 rounded-md cursor-pointer' onClick={() =>
                     handleRemoveItem(data.id)}>
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='ProductTableForMobile'>
          {cartItems.map((data, index) => (
            <div key={data._id} className='CartDataContainer'> {/* Ensure each mobile item has a unique key */}
              <span># {++index}</span>
              <span>
                <img src={IMG2} />
              </span>
              <span>Name</span>
              <span>{data.productName}</span>
              <span>Cat</span>
              <span>{data.productCat}</span>
              <span>Unit</span>
              <span>{data.productUnit}</span>
              <span>Quantity</span>
              <span>{data.quantity}</span>
              <span>Price</span>
              <span>{data.productPrice}</span>
              <span>Amount</span>
              <span>{data.productPrice * data.quantity}.00</span>
              <span>Action</span>
              <span className='bg-red-600 w-16 text-white p-2 rounded-md cursor-pointer' onClick={() => handleRemoveItem(data.id)}>
                Delete
              </span>
            </div>
          ))}
        </div>
      </div>

      <hr className='text-lg' />

      <div className='m-5 w-[30%] min-h-[30%] rounded-lg bg-white AmountContainer'>
        <div className='p-3 grid grid-cols-2 justify-center justify-items-center mt-4 text-lg'>
          <span>Total Amount :</span>
          <span>{grandTotal}.00</span>
          <span>Delivery :</span>
          <span>Free</span>
          <span className='text-xl text-lime-700'>Grand Total :</span>
          <span className='text-xl text-lime-700'>{grandTotal}.00</span>
        </div>
      </div>

      <div>
        <button onClick={CheckOutHandler} className='w-[32%] m-5 mt-1 p-2 text-white rounded-lg bg-[#069d30]'>
          Check Out
          {isLoading &&
                    <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                      <span className="sr-only"></span>
                    </div>}
        </button>
      </div>
    </div>
  );
}
