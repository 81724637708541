/* eslint-disable */
import React from 'react'
import DevliveryPic from "../../assets/DeliveryPic.jpg"
import "./DeliveryPromo.css"


export default function DeliveryPromo() {

  return (
    <div className='flex justify-center items-center mb-16 h-[50vh] DeliveryPromoContainer'>
        <img src={DevliveryPic} alt='OK' className='w-98vw h-[50vh] rounded-lg'/>
    </div>
  )
}
