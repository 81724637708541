/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

//All Components
import NavBar from './components/Navbar/NavBar';
import Home from './components/Home/Home';
import NewArrival from './components/NewArivals/NewArival';
import Stat from './components/Stats/Stats';
import DeliveryPromo from './components/DeliveryPromo/DeliveryPromo';
import Products from './components/Products/Products';
import ProductPreview from './components/ProductPreview/ProductPreview';
import CheckOut from './components/CheckOut/CheckOut';
import ContactUs from './components/ContactUs/ContactUs';
import OurGallery from './components/OurGallary/OurGallary';
import Preloader from './components/Preloader/Preloader';
import TrackMyOrder from './components/TrackMyOrder/TrackMyOrder';
import OrderConfirmation from './components/OrderConfirmation/OrderConfirmation';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Footer from './components/Footer/Footer';
import './App.css';





function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, []);

  const Layout = ({ children }) => (
    <div>
      <NavBar />
      {children}
      <Footer />
    </div>
  );

  return (
    <div className="">
      <Routes>
        <Route
          path="/"
          element={
            isLoading ? (
              <Preloader />
            ) : (
              <Layout>
                <Home />
                <NewArrival />
                <Stat />
                <DeliveryPromo />
                <Products />
                <h3 className="flex justify-center items-center font-bold text-5xl text-gray-500 Heading md:text-5xl">
                  LET's BUY
                </h3>
                <ProductPreview />
              </Layout>
            )
          }
        />

        <Route
          path="/NewArrival"
          element={
            <Layout>
              <NewArrival />
            </Layout>
          }
        />

        <Route
          path="/Products"
          element={
            <Layout>
              <Products />
            </Layout>
          }
        />

        <Route
          path="/OurGallery"
          element={
            <Layout>
              <OurGallery />
            </Layout>
          }
        />

        <Route
          path="/TrackMyOrder"
          element={
            <Layout>
              <TrackMyOrder />
            </Layout>
          }
        />

        <Route
          path="/Contact-Us"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />

        <Route
          path="/ProductsPreview/:id"
          element={
            <Layout>
              <ProductPreview />
            </Layout>
          }
        />

        <Route
          path="/CheckOut"
          element={
            <Layout>
              <CheckOut />
            </Layout>
          }
        />

        <Route
          path="/OrderConfirmation/:id"
          element={
            <Layout>
              <OrderConfirmation />
            </Layout>
          }
        />

        <Route
          path="/Login"
          element={user ? <Navigate to="/" /> : <Login />}
        />

        <Route
          path="/SignUp"
          element={<SignUp />}
        />
      </Routes>
    </div>
  );
}

export default App;