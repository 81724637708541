
import { createSlice } from '@reduxjs/toolkit';

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    isCheckingOut: false,
  },
  reducers: {
    startCheckout: (state) => {
      state.isCheckingOut = true;
    },
    cancelCheckout: (state) => {
      state.isCheckingOut = false;
    }
  },
});

export const { startCheckout, cancelCheckout } = checkoutSlice.actions;

export default checkoutSlice.reducer;
