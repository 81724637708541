import React from 'react'

export default function ForgotPassword() {
  return (
    <div>
    <form action="">
    <div className="row mb-2">
                <div className="col">
                  <label htmlFor="name" className="block mb-2">Enter Email</label>
                  <input type="text" id="name" className="form-control w-full p-2 border border-gray-300" placeholder="example@xyz.com" />
                </div>

                <div className="col">
                  <label htmlFor="email" className="block mb-2">Enter Phone No.</label>
                  <input type="email" id="email" className="form-control w-full p-2 border border-gray-300" placeholder="03xx xxxxxxx" />
                </div>
              </div>

              <div className="text-sm">
                <span  className="font-semibold cursor-pointer text-indigo-600 hover:text-indigo-500" onClick={()=>window.location.reload()}>
                  Back To Login
                </span>
              </div>

              <div className='mt-8'>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>

      </form>
    </div>
  )
}
