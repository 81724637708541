import React, { useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Link } from 'react-router-dom';
import { request } from '../../util/fetchAPI';
import "./TrackMyOrder.css";

export default function TrackMyOrder() {

  const [orderId, setOrderId] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [isOrderFetched, setIsOrderFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const grandTotal = orderData?.products?.reduce((total, data) => {
    return total + data.productPrice * data.qtyOrder;
  }, 0);

  const backPageHandler = () => {
    setOrderId('');
    setOrderData(null);
    setIsOrderFetched(false);
    setIsLoading(false);
  }

  const successNotifier = () => {
    toast.success("Order Found", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const errorNotifier = () => {
    toast.error("Order Not Found, Maybe Entering Wrong ID", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const fetchOrderData = async () => {
    try {
      if (orderId) {
        setIsLoading(true);
        const data = await request(`/order/trackOrderBy`, "POST", {
          "Content-Type": "application/json"
        }, { orderId });

        if (data.status === 200) {
          setOrderData(data.data);
          setIsLoading(false);
          setIsOrderFetched(true);
          successNotifier();
        } else {
          setIsLoading(false);
          errorNotifier();
        }
      } else {
        errorNotifier();
      }
    } catch (error) {
      setIsLoading(false);
      errorNotifier();
      console.error(error);
    }
  }

  return (
    <div>
      <ToastContainer />
      <h3 className='flex w-[100%] justify-center items-center font-bold text-5xl text-gray-500 Heading'>
        Order Tracker
      </h3>
      <div className='OrderTracker'>
        {!isOrderFetched &&
          <div className='TrackerForm'>
            <form action="">
              <fieldset className="border-2 border-black p-1">
                <legend className="font-bold text-blue-700">Order Information</legend>
                <div className="col w-96">
                  <label htmlFor="orderId" className="block mb-2">Order Number</label>
                  <input
                    type="text"
                    id="orderId"
                    className="form-control p-2 border border-gray-300"
                    placeholder="Enter Order Number"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </div>
              </fieldset>
              <div>
                <button
                  type='button'
                  onClick={fetchOrderData}
                  className='bg-indigo-600 text-white p-2 rounded-lg mt-3 mb-2 w-52'
                  disabled={isLoading}
                >
                  Search
                  {isLoading &&
                    <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                      <span className="sr-only"></span>
                    </div>}
                </button>
              </div>
            </form>
          </div>}

        {isOrderFetched && orderData &&
          <div className='Tracker'>
            <section className="h-auto" style={{ backgroundColor: '#eee' }} key={orderData._id}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-lg-8 col-xl-6">
                    <button
                      className='flex bg-red-500 text-white rounded-md p-2 m-1'
                      onClick={backPageHandler}
                    >
                      <IoIosArrowBack className='text-2xl' /> Back
                    </button>
                    <div className="card border-top border-bottom border-3" style={{ borderColor: '#f37a27 !important' }}>
                      <div className="card-body p-5">
                        <p className="lead fw-bold mb-5 text-indigo-800">Purchase Receipt</p>
                        <div className="row">
                          <div className="col mb-3">
                            <p className="small text-muted mb-1">Date</p>
                            <p>{orderData?.OrderData?.createdAt.slice(0,10)}</p>
                          </div>
                          <div className="col mb-3">
                            <p className="small text-muted mb-1">Order No.</p>
                            <p>{orderData?.OrderData?.orderId}</p>
                          </div>
                        </div>

                        {orderData?.products?.map((data) => (
                          <div className="mx-n5 px-5 py-4" style={{ backgroundColor: '#f2f2f2' }} key={data._id}>
                            <div className="row">
                              <div className="col-md-8 col-lg-9">
                                <p>{data.productId?.productName} x {data.qtyOrder}</p>
                              </div>
                              <div className="col-md-4 col-lg-3">
                                <p>{data.productId?.productSize}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8 col-lg-9">
                                <p className="mb-0">{data.productId?.productMaterial}</p>
                              </div>
                              <div className="col-md-4 col-lg-3">
                                <p className="mb-0">{data.productPrice}.00</p>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="row my-4">
                          <div className="col-md-4 offset-md-8 col-lg-3 offset-lg-9">
                            <p className="lead fw-bold mb-0 text-indigo-800">
                              {grandTotal}.00
                            </p>
                          </div>
                        </div>

                        <p className="lead fw-bold mb-4 pb-2 text-indigo-800">Tracking Order</p>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="horizontal-timeline">
                              <ul className="list-inline items d-flex justify-content-between">
                                <li className="list-inline-item items-list">
                                  <p className={orderData?.OrderData?.isProcessed ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Ordered
                                  </p>
                                </li>
                                <li className="list-inline-item items-list">
                                  <p className={orderData?.OrderData?.isConfirmed ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Confirmed
                                  </p>
                                </li>
                                <li className="list-inline-item items-list">
                                  <p className={orderData?.OrderData?.isShipped ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    On the way
                                  </p>
                                </li>
                                <li className="list-inline-item items-list text-end" style={{ marginRight: 8 }}>
                                  <p className={orderData?.OrderData?.isDelivered ? "py-1 px-2 rounded bg-lime-700 text-white" : "text-black"}>
                                    Delivered
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <p className="mt-4 pt-2 mb-0">
                          Want any help?{' '}
                          <Link to='/Contact-Us' style={{ color: '#f37a27' }}>
                            Please contact us
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </div>
    </div>
  )
}
