import { useState } from "react"
import { useDispatch } from 'react-redux'
import { request } from '../../util/fetchAPI'
import { login } from '../../redux/authSlice'
import { Link, useNavigate } from "react-router-dom"
import ForgotPassword from "../ForgotPassword/ForgotPassword"
import Logo from "../../assets/Logo.jpg"
import "./Login.css"

export default function Login() {

  const [forgotPass, setForgotPass] = useState(false)
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  //Register Products Catagories
  const handleRegister = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const data = await request("/user/UserLogin", "POST", {
        "Content-Type": "application/json"
      }, { ...state })
      dispatch(login(data.data))
      setIsLoading(false)
      navigate("/")

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="h-[100vh] w-[100%] flex justify-center items-center bg-slate-50">
      {!forgotPass ?
        <div className="flex h-[65vh] w-[50%] m-2 justify-center items-center px-6 py-12 lg:px-8 border-2 border-gray-500 rounded-lg Form">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Link to="/">
              <img
                alt="Your Company"
                src={Logo}
                className="mx-auto h-20 w-52"
              />
            </Link>

            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Login to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleRegister} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900" >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState} />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <span className="font-semibold text-indigo-600 hover:text-indigo-500" onClick={() => setForgotPass((prev) => !prev)}>
                      Forgot password?
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState} />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login
                  {isLoading &&
                    <div className="spinner-border" style={{ height: "20px", width: "20px", marginLeft: "5px" }} role="status">
                      <span className="sr-only"></span>
                    </div>}
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{' '}
              <Link to="/SignUp" className="font-semibold leading-6 text-indigo-600 no-underline hover:text-indigo-500">Create Account</Link>
            </p>
          </div>
        </div>
        :
        <div className="flex h-[40vh] w-[40%] m-2 justify-center items-center px-6 py-12 lg:px-8 border-2 border-gray-500 rounded-lg Form">
          <ForgotPassword />
        </div>

      }

    </div>


  )
}